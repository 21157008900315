export const ADD_STUDENT = 'ADD_STUDENT';
export const GET_STUDENT = 'GET_STUDENT';
export const SINGLE_STUDENT = 'SINGLE_STUDENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const STATUS_STUDENT = 'STATUS_STUDENT';
export const CLASS_DETAIL = 'CLASS_DETAIL';
export const FEE_DETAIL = 'FEE_DETAIL';
export const GET_DISCOUNT = 'GET_DISCOUNT';
export const FEE_LEDGER = 'FEE_LEDGER';
