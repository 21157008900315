import * as React from 'react';
const Login = React.lazy(() => import('./pages/Authentication/Login'));
const LockScreen = React.lazy(() => import('./pages/Authentication/LockScreen'));
const ResetPassword = React.lazy(() => import('./pages/Authentication/ResetPassword'));

const route = [
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/', exact: true, name: 'Login', component: Login },
    { path: '/lockscreen', exact: true, name: 'Login', component: LockScreen },
    { path: '/reset-password', exact: true, name: 'ResetPassword', component: ResetPassword },
    
];
export default route;
