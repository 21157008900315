import * as React from 'react';
// Admin Start
const Dashboard = React.lazy(() => import('./pages/Admin/Dashboard/Dashboard'));
const ManageFranchise = React.lazy(() => import('./pages/Admin/Masters/ManageFranchiser/ManageFranchiser'));
const AddFranchiser = React.lazy(() => import('./pages/Admin/Masters/ManageFranchiser/AddFranchiser'));
const EditFranchiser = React.lazy(() => import('./pages/Admin/Masters/ManageFranchiser/EditFranchiser'));
const ManagePlans = React.lazy(() => import('./pages/Admin/Masters/ManagePlans/ManagePlans'));
const AdminManageClasses = React.lazy(() => import('./pages/Admin/Masters/ManageClasses/ManageClasses'));
const School = React.lazy(() => import('./pages/Admin/School/School'));
const Client = React.lazy(() => import('./pages/Admin/Client/Client'));
const AddSchool = React.lazy(() => import('./pages/Admin/School/AddSchool'));
const EditSchool = React.lazy(() => import('./pages/Admin/School/EditSchool'));
const Users = React.lazy(() => import('./pages/Admin/Users/Users'));
const Designation = React.lazy(() => import('./pages/Admin/Users/Designation'));
const Test = React.lazy(() => import('./pages/Test'));
const RolesAndPermission = React.lazy(() => import('./pages/Admin/Users/RolesAndPermission'));
const Profile = React.lazy(() => import('./pages/Admin/Profile/Profile'));
const Features = React.lazy(() => import('./pages/Admin/Setting/Features/Features'));
const Level = React.lazy(() => import('./pages/Admin/Setting/Level/Level'));
const Currency = React.lazy(() => import('./pages/Admin/Setting/Currency/Currency'));
const PlanType = React.lazy(() => import('./pages/Admin/Setting/PlanType/PlanType'));
const Season = React.lazy(() => import('./pages/Admin/Setting/Season/Season'));
const FeeStructure = React.lazy(() => import('./pages/Admin/Setting/FeeStructure/FeeStructure'));

// Admin End
// School Start
const SchoolDashboard = React.lazy(() => import('./pages/School/Dashboard/Dashboard'));
const ManageClasses = React.lazy(() => import('./pages/School/Master/ManageClasses/ManageClasses'));
const ManageSchool = React.lazy(() => import('./pages/School/School/School'));
const AddSchool1 = React.lazy(() => import('./pages/School/School/AddSchool'));
//const EditSchool1 = React.lazy(() => import('./pages/School/School/EditSchool'));
const ManageSubject = React.lazy(() => import('./pages/School/Master/ManageSubject/ManageSubject'));
const SchoolUsers = React.lazy(() => import('./pages/School/Users/Users'));
const SchoolDesignation = React.lazy(() => import('./pages/School/Users/Designation'));
const SchoolRolesAndPermission = React.lazy(() => import('./pages/School/Users/RolesAndPermission'));
const ManageStudent = React.lazy(() => import('./pages/School/Student/Student'));
const AddStudent = React.lazy(() => import('./pages/School/Student/AddStudent'));
const EditStudent = React.lazy(() => import('./pages/School/Student/EditStudent'));
const SchoolProfile = React.lazy(() => import('./pages/School/Profile/Profile'));
const TimeTable = React.lazy(() => import('./pages/School/TimeTable/TimeTable'));
const AddTimeTable = React.lazy(() => import('./pages/School/TimeTable/AddTimeTable'));
const EditTimeTable = React.lazy(() => import('./pages/School/TimeTable/EditTimeTable'));
const TimePeriod = React.lazy(() => import('./pages/School/Setting/TimePeriod'));
const Event = React.lazy(() => import('./pages/School/Event/Event'));
const ManageShift = React.lazy(() => import('./pages/School/Setting/ManageShift'));
const ManageSeason = React.lazy(() => import('./pages/School/Setting/ManageSeason'));
const Promote = React.lazy(() => import('./pages/School/Student/Promote/Promote'));
const TeacherTimeTable = React.lazy(() => import('./pages/School/TimeTable/TeacherTimeTable'));
const Attendance = React.lazy(() => import('./pages/School/Student/Attendance/Attendance'));
const AddAttendance = React.lazy(() => import('./pages/School/Student/Attendance/AddAttendance'));
const EditAttendance = React.lazy(() => import('./pages/School/Student/Attendance/EditAttendance'));
const Assignment = React.lazy(() => import('./pages/School/Student/Assignment/Assignment'));
const FeeDetail = React.lazy(() => import('./pages/School/Student/FeeDetail/FeeDetail'));
const FeeReport = React.lazy(() => import('./pages/School/Student/FeeDetail/FeeReport'));
const SchoolFeeStructure = React.lazy(() => import('./pages/School/Setting/SchoolFeeStructure'));
const ManagePaymentType = React.lazy(() => import('./pages/School/Setting/ManagePaymentType'));
const AddFee = React.lazy(() => import('./pages/School/Student/FeeDetail/AddFee'));
const EditFee = React.lazy(() => import('./pages/School/Student/FeeDetail/EditFee'));

// School End

const routes = [
    // Admin Start
    { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/admin/masters/manage-franchiser', exact: true, name: 'Manage Franchiser', component: ManageFranchise },
    { path: '/admin/masters/manage-classes', exact: true, name: 'Manage Classes', component: AdminManageClasses },
    { path: '/admin/masters/add-franchiser', exact: true, name: 'Add Franchiser', component: AddFranchiser },
    { path: '/admin/masters/edit-franchiser/:id', exact: true, name: 'Edit Franchiser', component: EditFranchiser },
    { path: '/admin/masters/manage-plans', exact: true, name: 'Manage Plans', component: ManagePlans },
    { path: '/admin/school', exact: true, name: 'School', component: School },
    { path: '/admin/clients', exact: true, name: 'Client', component: Client },
    { path: '/admin/add-school', exact: true, name: 'Add School', component: AddSchool },
    { path: '/admin/edit-school/:id', exact: true, name: 'ManageSubject', component: EditSchool },
    { path: '/admin/users', exact: true, name: 'Users', component: Users },
    { path: '/admin/designation', exact: true, name: 'Designation', component: Designation },
    { path: '/admin/roles-and-permission', exact: true, name: 'RolesAndPermission', component: RolesAndPermission },
    { path: '/admin/test', exact: true, name: 'Test', component: Test },
    { path: '/admin/profile', exact: true, name: 'Profile', component: Profile },
    { path: '/admin/features', exact: true, name: 'Features', component: Features },
    { path: '/admin/level', exact: true, name: 'Level', component: Level },
    { path: '/admin/currency', exact: true, name: 'Currency', component: Currency },
    { path: '/admin/plan-type', exact: true, name: 'Plantype', component: PlanType },
    { path: '/admin/setting/season', exact: true, name: 'Season', component: Season },
    { path: '/admin/setting/fee-structure', exact: true, name: 'FeeStructure', component: FeeStructure },
    // Admin End
    // School Start
    { path: '/school/dashboard', exact: true, name: 'SchoolDashboard', component: SchoolDashboard },
    { path: '/school/masters/manage-classes', exact: true, name: 'Manage Classes', component: ManageClasses },
    { path: '/school/manage-school', exact: true, name: 'ManageSchool', component: ManageSchool },
    { path: '/school/add-school', exact: true, name: 'Add School', component: AddSchool1 },
    { path: '/school/masters/manage-subject', exact: true, name: 'Manage Subject', component: ManageSubject },
    { path: '/school/users', exact: true, name: 'Users', component: SchoolUsers },
    { path: '/school/designation', exact: true, name: 'Designation', component: SchoolDesignation },
    { path: '/school/roles-and-permission', exact: true, name: 'RolesAndPermission', component: SchoolRolesAndPermission },
    { path: '/school/manage-student', exact: true, name: 'Manage Student', component: ManageStudent },
    { path: '/school/add-student', exact: true, name: 'Add Student', component: AddStudent },
    { path: '/school/edit-student/:id', exact: true, name: 'Edit Student', component: EditStudent },
    { path: '/school/profile', exact: true, name: 'School Profile', component: SchoolProfile },
    { path: '/school/timetable', exact: true, name: 'TimeTable', component: TimeTable },
    { path: '/school/add-timetable', exact: true, name: 'Add TimeTable', component: AddTimeTable },
    { path: '/school/edit-timetable', exact: true, name: 'Add TimeTable', component: EditTimeTable },
    { path: '/school/time-period', exact: true, name: 'TimePeriod', component: TimePeriod },
    { path: '/school/event', exact: true, name: 'Event', component: Event },
    { path: '/school/manage-shift', exact: true, name: 'ManageShift', component: ManageShift },
    { path: '/school/manage-season', exact: true, name: 'ManageSeason', component: ManageSeason },
    { path: '/school/promote', exact: true, name: 'Promote', component: Promote },
    { path: '/school/teacher-time-table/:id', exact: true, name: 'TeacherTimeTable', component: TeacherTimeTable },
    { path: '/school/student/attendance', exact: true, name: 'Attendance', component: Attendance },
    { path: '/school/student/add-attendance', exact: true, name: 'AddAttendance', component: AddAttendance },
    { path: '/school/student/edit-attendance/:id', exact: true, name: 'EditAttendance', component: EditAttendance },
    { path: '/school/student/assignment', exact: true, name: 'Assignment', component: Assignment },
    { path: '/school/student/fee-detail', exact: true, name: 'FeeDetail', component: FeeDetail },
    { path: '/school/student/fee-report', exact: true, name: 'FeeReport', component: FeeReport },
    { path: '/school/setting/school-fee-structure', exact: true, name: 'SchoolFeeStructure', component: SchoolFeeStructure },
    { path: '/school/manage-payment-type', exact: true, name: 'ManagePaymentType', component: ManagePaymentType },
    { path: '/school/student/add-fee', exact: true, name: 'AddFee', component: AddFee },
    { path: '/school/student/edit-fee/:id', exact: true, name: 'EditFee', component: EditFee }

    // School End
];
export default routes;
