import {
    ADD_SCHOOL_USERTYPE,
    GET_SCHOOL_USERTYPES,
    GETSINGLE_SCHOOL_USERTYPE,
    UPDATE_SCHOOL_USERTYPE,
    DELETE_SCHOOL_USERTYPE,
    PERMISSION_SCHOOL_DATA
} from './actiontype';

const INITIAL_STATE = {
    usertypes: [],
    singleUsertype: [],
    permission_list: ''
};

const schoolUsertypeReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SCHOOL_USERTYPE:
            return {
                ...state,
                usertypes: [...state.usertypes, payload]
            };
        case GET_SCHOOL_USERTYPES:
            return {
                ...state,
                usertypes: payload
            };
        case GETSINGLE_SCHOOL_USERTYPE:
            return {
                ...state,
                singleUsertype: payload
            };
        case UPDATE_SCHOOL_USERTYPE:
            return {
                ...state,
                usertypes: state.usertypes.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case PERMISSION_SCHOOL_DATA:
            return {
                ...state,
                permission_list: payload
            };
        case DELETE_SCHOOL_USERTYPE:
            return {
                ...state,
                usertypes: state.usertypes.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default schoolUsertypeReducer;
