import {
    ADD_TIMETABLE,
    GET_TIMETABLES,
    SINGLE_TIMETABLE,
    UPDATE_TIMETABLE,
    DELETE_TIMETABLE,
    TIMEPERIOD_LIST,
    SHIFT_LIST,
    TEACHER_LIST,
    TIMETABLE_LIST,
    TIMETABLE_DELETE,
    GET_MANAGE_TIMETABLE,
    SEARCH_DATA,
    GET_SINGLE_PERIOD,
    DELETE_TIMETABLE_SESSION
} from './actiontype';

const INITIAL_STATE = {
    timetables: [],
    singleTimetable: [],
    timeperiodList: [],
    timeperiodData: [],
    shiftList: [],
    teacherList: [],
    isAdded: false,
    manageTimetable: [],
    timetableData: []
};

const schoolTimetableReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_TIMETABLE:
            return {
                ...state,
                timetables: [...state.timetables, payload]
            };
        case GET_TIMETABLES:
            return {
                ...state,
                timetableData: payload,
                isAdded: false
            };
        case DELETE_TIMETABLE_SESSION:
            return {
                ...state,
                timetableData: state.timetableData.filter((item) => parseInt(item.session_id) !== parseInt(payload))
            };
        case SINGLE_TIMETABLE:
            return {
                ...state,
                singleTimetable: payload
            };
        case UPDATE_TIMETABLE:
            return {
                ...state,
                timetables: state.timetables.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_TIMETABLE:
            return {
                ...state,
                timetables: state.timetables.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        case TIMEPERIOD_LIST:
            return {
                ...state,
                timeperiodList: payload
            };
        case SHIFT_LIST:
            return {
                ...state,
                shiftList: payload
            };
        case TEACHER_LIST:
            return {
                ...state,
                teacherList: payload
            };
        case TIMETABLE_DELETE:
            return {
                ...state,
                timetables: state.timetables.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        case TIMETABLE_LIST:
            return {
                ...state,
                isAdded: true
            };
        case GET_MANAGE_TIMETABLE:
            return {
                ...state,
                manageTimetable: payload
            };
        case SEARCH_DATA:
            return {
                ...state,
                timetables: payload
            };
        case GET_SINGLE_PERIOD:
            return {
                ...state,
                timetables: payload
            };
        default:
            return state;
    }
};
export default schoolTimetableReducer;
