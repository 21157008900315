import { GET_ASSIGNMENT, SINGLE_ASSIGNMENT, ADD_ASSIGNMENT, UPDATE_ASSIGNMENT, DELETE_ASSIGNMENT } from './actiontype';

const INITIAL_STATE = {
    assignmentLists: [],
    singleList: []
};
const assignmentReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_ASSIGNMENT:
            return {
                ...state,
                assignmentLists: [...state.assignmentLists, payload]
            };
        case GET_ASSIGNMENT:
            return {
                ...state,
                assignmentLists: payload
            };
        case SINGLE_ASSIGNMENT:
            return {
                ...state,
                singleList: payload
            };
        case UPDATE_ASSIGNMENT:
            return {
                ...state,
                assignmentLists: state.assignmentLists.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_ASSIGNMENT:
            return {
                ...state,
                assignmentLists: state.assignmentLists.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default assignmentReducer;
