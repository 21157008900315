import {
   LOGIN,
   LOGOUT,
   SETDATA,
   FORGET_PASSWORD
} from './actiontype';

const INITIAL_STATE = {
  isLogin: false,
  isLogout: false,
  isPasswordSet: false
};
const authenticationReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
    case LOGIN:
      return {
        ...state,
        isLogin: true,
      };
    case LOGOUT:
      return {
        ...state,
        isLogout: true,
      };
    case SETDATA:
      return {
        ...state,
        isLogin: false,
        isLogout: false,
      };
    case FORGET_PASSWORD:
      return {
        ...state,
        isPasswordSet: true
      };
    default:
      return state;
  }
};
export default authenticationReducer;