import { GET_TIMEPERIOD } from './actiontype';

const INITIAL_STATE = {
    timeperiods: []
};

const timeperiodReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TIMEPERIOD:
            return {
                ...state,
                timeperiods: payload
            };
        default:
            return state;
    }
};
export default timeperiodReducer;
