import {
    ADD_DESIGNATION,
    UPDATE_DESIGNATION,
    DELETE_DESIGNATION,
    GET_DESIGNATION,
    SINGLE_DESIGNATION,
    EDIT_USERTYPELIST
} from './actiontype';

const INITIAL_STATE = {
    designations: [],
    singleDesignation: [],
    editUsertypeList: []
};
const designationReducer = (state1 = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_DESIGNATION:
            return {
                ...state1,
                designations: [...state1.designations, payload]
            };
        case GET_DESIGNATION:
            return {
                ...state1,
                designations: payload
            };
        case SINGLE_DESIGNATION:
            return {
                ...state1,
                singleDesignation: payload
            };
        case UPDATE_DESIGNATION:
            return {
                ...state1,
                designations: state1.designations.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_DESIGNATION:
            return {
                ...state1,
                designations: state1.designations.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        case EDIT_USERTYPELIST:
            return {
                ...state1,
                editUsertypeList: payload
            };
        default:
            return state1;
    }
};
export default designationReducer;
