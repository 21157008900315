import { ADD_SESSION_TYPE, GET_SESSION_TYPE, GETSINGLE_SESSION_TYPE, UPDATE_SESSION_TYPE, DELETE_SESSION_TYPE } from './actiontype';

const INITIAL_STATE = {
    sessionType: [],
    singleSessionType: []
};

const schoolSeasonPaymentReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        // case ADD_SCHOOL_SEASON:
        //     return {
        //         ...state,
        //         sessionType: [...state.sessionType, payload]
        //     };
        case GET_SESSION_TYPE:
            return {
                ...state,
                sessionType: payload
            };
        // case GETSINGLE_SCHOOL_SEASON:
        //     return {
        //         ...state,
        //         singleSeason: payload
        //     };
        // case UPDATE_SCHOOL_SEASON:
        //     return {
        //         ...state,
        //         sessionType: payload
        //     };
        // case DELETE_SCHOOL_SEASON:
        //     return {
        //         ...state,
        //         sessionType: state.sessionType.filter((item) => parseInt(item.id) !== parseInt(payload))
        //     };
        default:
            return state;
    }
};
export default schoolSeasonPaymentReducer;
