import * as React from 'react';
import { Row, Col, Dropdown, Modal, Button } from 'react-bootstrap';
import Avatar1 from '../../../../../assets/images/user.jpg';
import { useState, useEffect } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { logout, changePassword } from '../../../../../redux/authentication/action';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { ToastContainer } from 'react-toastify';

const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required('Old Password is required'),
    newPassword: Yup.string().required('Enter New Password'),
    confirmPassword: Yup.string().required('Confirm Password is Required')
});

const NavRight = ({ logout, isLogout, changePassword, isLogin }) => {
    const history = useHistory();

    useEffect(() => {
        if (isLogout) {
            history.push('/');
        }

        if (isLogin) {
            history.push('/');
        }
    });

    const signout = (e) => {
        logout();
    };

    const getCurrentUrl = (e) => {
        localStorage.setItem('lastUrl', window.location.pathname);
        history.push('/lockscreen');
    };

    const [isOpen, setIsOpen] = useState(false);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('user_info'));
        if (items) {
            setUserName(items.name);
        }
    }, []);

    return (
        <>
            <ul className="navbar-nav ml-auto">
                <li>
                    <Dropdown>
                        <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                            <i className="icon feather icon-user" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head bg-dark">
                                <img src={Avatar1} className="img-radius" alt="User Profile" />
                                <span>{userName}</span>
                            </div>
                            <ul className="pro-body">
                                <li>
                                    <a href="/school/profile" className="dropdown-item">
                                        <i className="feather icon-user" /> Profile
                                    </a>
                                </li>
                                <li>
                                    <a href="/school/manage-school" className="dropdown-item">
                                        <i className="feather icon-user" /> School
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" type="button" onClick={() => setIsOpen(true)}>
                                        <i className="feather icon-unlock" /> Change Password
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" type="button" onClick={() => getCurrentUrl(true)}>
                                        <i className="feather icon-lock" /> Lock Screen
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" type="button" onClick={() => signout(true)}>
                                        <i className="feather icon-log-out" /> Log Out
                                    </a>
                                </li>
                            </ul>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
            {/* change password modal start */}
            <Modal size="md" centered show={isOpen} onHide={() => setIsOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Change Password</Modal.Title>
                </Modal.Header>
                <Formik
                    validationSchema={changePasswordSchema}
                    initialValues={{ password: '', newPassword: '', confirmPassword: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                        changePassword(values);
                        setIsOpen(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-group fill">
                                            <label className="floating-label">Current Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder=" Current Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage component="div" name="password" className="error-message" />
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="form-group fill">
                                            <label className="floating-label">New Password</label>
                                            <input
                                                type="password"
                                                name="newPassword"
                                                className="form-control"
                                                placeholder="New Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage component="div" name="newPassword" className="error-message" />
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="form-group fill">
                                            <label className="floating-label">Confirm Password</label>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage component="div" name="confirmPassword" className="error-message" />
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={() => setIsOpen(false)}>
                                    Clear
                                </Button>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </form>
                    )}
                </Formik>
            </Modal>
            {/* Change password modal end */}
            <ToastContainer />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogout: state.authentication.isLogout,
        isLogin: state.authentication.isLogin
    };
};

export default connect(mapStateToProps, { logout, changePassword })(NavRight);
