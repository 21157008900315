import {
    STUDENT_LIST,
    FEE_DETAIL,
    STUDENT_DETAIL,
    DUE_AMOUNT,
    ADD_FEE,
    ADD_TO_CART,
    VIEW_CART,
    DELETE_CART,
    TOTAL_AMOUNT,
    EDIT_FEE,
    GET_SINGLE_PAYMENT_FEE,
    UPDATE_FEE
} from './actiontype';

const INITIAL_STATE = {
    students: [],
    fee_detail: [],
    student_detail: [],
    due_amount: 0,
    fee_cart_items: [],
    total_amount: 0,
    isAdded: false,
    isUpdated: false,
    singleData: [],
    feeItemList: [],
    singleFeetype: []
};
const FeesReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case STUDENT_LIST:
            return {
                ...state,
                students: payload
            };
        case FEE_DETAIL:
            return {
                ...state,
                fee_detail: payload,
                isAdded: false,
                isUpdated: false
            };
        case STUDENT_DETAIL:
            return {
                ...state,
                student_detail: payload
            };
        case DUE_AMOUNT:
            return {
                ...state,
                due_amount: payload
            };
        case TOTAL_AMOUNT:
            return {
                ...state,
                total_amount: payload
            };
        case ADD_TO_CART:
            return {
                ...state,
                fee_cart_items: [...state.fee_cart_items, payload]
            };
        case DELETE_CART:
            return {
                ...state,
                fee_cart_items: state.fee_cart_items.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        case ADD_FEE:
            return {
                ...state,
                isAdded: true
            };
        case UPDATE_FEE:
            return {
                ...state,
                isUpdated: true
            };
        case EDIT_FEE:
            return {
                ...state,
                singleData: payload
            };
        case VIEW_CART:
            return {
                ...state,
                feeItemList: payload
            };
        case GET_SINGLE_PAYMENT_FEE:
            return {
                ...state,
                singleFeetype: payload
            };
        default:
            return state;
    }
};
export default FeesReducer;
