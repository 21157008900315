import {
    ADD_SCHOOL_TIMEPERIOD,
    GET_SCHOOL_TIMEPERIODS,
    GETSINGLE_SCHOOL_TIMEPERIOD,
    UPDATE_SCHOOL_TIMEPERIOD,
    DELETE_SCHOOL_TIMEPERIOD
} from './actiontype';

const INITIAL_STATE = {
    timeperiodList: [],
    singleTimeperiod: []
};

const schoolTimeperiodReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SCHOOL_TIMEPERIOD:
            return {
                ...state,
                timeperiodList: [...state.timeperiodList, payload]
            };
        case GET_SCHOOL_TIMEPERIODS:
            return {
                ...state,
                timeperiodList: payload
            };
        case GETSINGLE_SCHOOL_TIMEPERIOD:
            return {
                ...state,
                singleTimeperiod: payload
            };
        case UPDATE_SCHOOL_TIMEPERIOD:
            return {
                ...state,
                timeperiodList: state.timeperiodList.map((row) =>
                    row.id === payload.id
                        ? {
                              ...payload
                          }
                        : row
                )
            };
        case DELETE_SCHOOL_TIMEPERIOD:
            return {
                ...state,
                timeperiodList: state.timeperiodList.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default schoolTimeperiodReducer;
