import {
    GET_COUNTRIES,
    GET_STATES
} from './actiontype';

const INITIAL_STATE = {
  countries: [],
  states: [],
};
const countryReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      };
    case GET_STATES:
      return {
        ...state,
        states: payload,
      };
    default:
      return state;
  }
};
export default countryReducer;