export const GET_TIMETABLES = 'GET_TIMETABLES';

// search
export const SEARCH_DATA = 'SEARCH_DATA';
export const SEARCH_TIMETABLE = 'SEARCH_TIMETABLE';
export const GET_SINGLE_PERIOD = 'GET_SINGLE_PERIOD';

export const ADD_TIMETABLE = 'ADD_TIMETABLE';
export const SINGLE_TIMETABLE = 'SINGLE_TIMETABLE';
export const UPDATE_TIMETABLE = 'UPDATE_TIMETABLE';
export const DELETE_TIMETABLE = 'DELETE_TIMETABLE';
export const TIMEPERIOD_LIST = 'TIMEPERIOD_LIST';
export const SHIFT_LIST = 'SHIFT_LIST';
export const TEACHER_LIST = 'TEACHER_LIST';
export const TIMETABLE_LIST = 'TIMETABLE_LIST';
export const TIMETABLE_DELETE = 'TIMETABLE_DELETE';
export const GET_MANAGE_TIMETABLE = 'GET_MANAGE_TIMETABLE';
export const DELETE_TIMETABLE_SESSION = 'DELETE_TIMETABLE_SESSION';
