import {
    ADD_SCHOOL_DESIGNATION,
    UPDATE_SCHOOL_DESIGNATION,
    DELETE_SCHOOL_DESIGNATION,
    GET_SCHOOL_DESIGNATION,
    SINGLE_SCHOOL_DESIGNATION,
    EDIT_SCHOOL_USERTYPELIST
} from './actiontype';

const INITIAL_STATE = {
    designations: [],
    singleDesignation: [],
    editUsertypeList: []
};
const schoolDesignationReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SCHOOL_DESIGNATION:
            return {
                ...state,
                designations: [...state.designations, payload]
            };
        case GET_SCHOOL_DESIGNATION:
            return {
                ...state,
                designations: payload
            };
        case SINGLE_SCHOOL_DESIGNATION:
            return {
                ...state,
                singleDesignation: payload
            };
        case UPDATE_SCHOOL_DESIGNATION:
            return {
                ...state,
                designations: state.designations.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_SCHOOL_DESIGNATION:
            return {
                ...state,
                designations: state.designations.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        case EDIT_SCHOOL_USERTYPELIST:
            return {
                ...state,
                editUsertypeList: payload
            };
        default:
            return state;
    }
};
export default schoolDesignationReducer;
