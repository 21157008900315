export const STUDENT_LIST = 'STUDENT_LIST';
export const FEE_DETAIL = 'FEE_DETAIL';
export const STUDENT_DETAIL = 'STUDENT_DETAIL';
export const DUE_AMOUNT = 'DUE_AMOUNT';
export const ADD_FEE = 'ADD_FEE';
export const ADD_TO_CART = 'ADD_TO_CART';
export const VIEW_CART = 'VIEW_CART';
export const DELETE_CART = 'DELETE_CART';
export const TOTAL_AMOUNT = 'TOTAL_AMOUNT';
export const EDIT_FEE = 'EDIT_FEE';
export const GET_SINGLE_PAYMENT_FEE = 'GET_SINGLE_PAYMENT_FEE';
export const UPDATE_FEE = 'UPDATE_FEE';
