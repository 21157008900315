import {
    GET_CURRENCIES,
    GET_CURRENCY,
    ADD_CURRENCY,
    SINGLE_CURRENCY,
    UPDATE_CURRENCY,
    DELETE_CURRENCY,
    UPDATE_STATUS_CURRENCY
} from './actiontype';

const INITIAL_STATE = {
    currencies: [],
    currencyList: [],
    singleList: []
};
const currencyReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CURRENCIES:
            return {
                ...state,
                currencies: payload
            };
        case ADD_CURRENCY:
            return {
                ...state,
                currencyList: [...state.currencyList, payload]
            };
        case GET_CURRENCY:
            return {
                ...state,
                currencyList: payload
            };
        case SINGLE_CURRENCY:
            return {
                ...state,
                singleList: payload
            };
        case UPDATE_CURRENCY:
            return {
                ...state,
                currencyList: payload
            };
        case UPDATE_STATUS_CURRENCY:
            return {
                ...state,
                currencyList: state.currencyList.map((row) =>
                    row.id === payload.id
                        ? {
                              ...payload
                          }
                        : row
                )
            };
        case DELETE_CURRENCY:
            return {
                ...state,
                currencyList: state.currencyList.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default currencyReducer;
