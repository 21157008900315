import { STUDENT_LIST, SET_PROMOTED, STUDENT_PROMOTED } from './actiontype';

const INITIAL_STATE = {
    students: [],
    isPromoted: false
};

const studentPromoteReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_PROMOTED:
            return {
                ...state,
                isPromoted: false,
                students: []
            };
        case STUDENT_LIST:
            return {
                ...state,
                students: payload
            };
        case STUDENT_PROMOTED:
            return {
                ...state,
                isPromoted: true
            };
        default:
            return state;
    }
};
export default studentPromoteReducer;
