import { GET_LEVEL, ADD_LEVEL, GET_LEVELS, SINGLE_LEVEL, UPDATE_LEVEL, DELETE_LEVEL, UPDATE_STATUS_LEVEL } from './actiontype';

const INITIAL_STATE = {
    levels: [],
    levelList: [],
    singleList: []
};
const levelReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_LEVEL:
            return {
                ...state,
                levels: payload
            };
        case ADD_LEVEL:
            return {
                ...state,
                levelList: [...state.levelList, payload]
            };
        case GET_LEVELS:
            return {
                ...state,
                levelList: payload
            };
        case SINGLE_LEVEL:
            return {
                ...state,
                singleList: payload
            };
        case UPDATE_STATUS_LEVEL:
            return {
                ...state,
                levelList: state.levelList.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_LEVEL:
            return {
                ...state,
                levelList: state.levelList.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default levelReducer;
