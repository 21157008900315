import { ADD_SCHOOL_SEASON, GET_SCHOOL_SEASONS, GETSINGLE_SCHOOL_SEASON, UPDATE_SCHOOL_SEASON, DELETE_SCHOOL_SEASON } from './actiontype';

const INITIAL_STATE = {
    seasonList: [],
    singleSeason: []
};

const schoolSeasonReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SCHOOL_SEASON:
            return {
                ...state,
                seasonList: [...state.seasonList, payload]
            };
        case GET_SCHOOL_SEASONS:
            return {
                ...state,
                seasonList: payload
            };
        case GETSINGLE_SCHOOL_SEASON:
            return {
                ...state,
                singleSeason: payload
            };
        case UPDATE_SCHOOL_SEASON:
            return {
                ...state,
                seasonList: payload
            };
        case DELETE_SCHOOL_SEASON:
            return {
                ...state,
                seasonList: state.seasonList.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default schoolSeasonReducer;
