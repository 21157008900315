import { GET_FEESTRUCTURE, ADD_FEESTRUCTURE, SINGLE_FEESTRUCTURE, UPDATE_FEESTRUCTURE, DELETE_FEESTRUCTURE } from './actiontype';

const INITIAL_STATE = {
    feestructures: [],
    singleList: []
};
const feeStructureReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_FEESTRUCTURE:
            return {
                ...state,
                feestructures: payload
            };
        case ADD_FEESTRUCTURE:
            return {
                ...state,
                feestructures: [...state.feestructures, payload]
            };
        case SINGLE_FEESTRUCTURE:
            return {
                ...state,
                singleList: payload
            };
        case UPDATE_FEESTRUCTURE:
            return {
                ...state,
                feestructures: state.feestructures.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_FEESTRUCTURE:
            return {
                ...state,
                feestructures: state.feestructures.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default feeStructureReducer;
