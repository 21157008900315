import axios from 'axios';

//baseURL: "http://localhost:9000/api/",
// baseURL: "https://zunior-api.techiesquad.in/public/api",
// baseURL: 'http://127.0.0.1:8000/api/',
// http://localhost:8080/api/

// export default axios.create({
//     baseURL: 'http://127.0.0.1:8000/api/',
//     headers: {
//         'Content-type': 'application/json'
//     }
// });

export default axios.create({
    baseURL: 'https://zunior-api.techiesquad.in/public/api/',
    headers: {
        'Content-type': 'application/json'
    }
});

// export default axios.create({
//     baseURL: 'https://wwwbros.com/public/api/',
//     headers: {
//         'Content-type': 'application/json'
//     }
// });
