import { ADD_SEASON, GET_SEASONS, GETSINGLE_SEASON, UPDATE_SEASON, DELETE_SEASON } from './actiontype';

const INITIAL_STATE = {
    seasons: [],
    singleSeason: []
};

const seasonReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SEASON:
            return {
                ...state,
                seasons: [...state.seasons, payload]
            };
        case GET_SEASONS:
            return {
                ...state,
                seasons: payload
            };
        case GETSINGLE_SEASON:
            return {
                ...state,
                singleSeason: payload
            };
        case UPDATE_SEASON:
            return {
                ...state,
                seasons: payload
            };
        case DELETE_SEASON:
            return {
                ...state,
                seasons: state.seasons.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default seasonReducer;
