import {
    ADD_PLAN,
    GET_PLANS,
    GETSINGLE_PLAN,
    UPDATE_PLAN,
    DELETE_PLAN,
    GETPLAN_DURATION,
    PLAN_FEATURE,
    UPDATE_STATUS_PLAN,
    ADD_PLANFEATURE,
    DELETE_PLANFEATURE
} from './actiontype';

const INITIAL_STATE = {
    plans: [],
    singlePlans: [],
    days: 0,
    features: []
};
const planReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_PLAN:
            return {
                ...state,
                plans: [...state.plans, payload]
            };
        case GET_PLANS:
            return {
                ...state,
                plans: payload
            };
        case GETSINGLE_PLAN:
            return {
                ...state,
                singlePlans: payload
            };
        case UPDATE_PLAN:
            return {
                ...state,
                plans: state.plans.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_PLAN:
            return {
                ...state,
                plans: state.plans.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        case UPDATE_STATUS_PLAN:
            return {
                ...state,
                plans: state.plans.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case ADD_PLANFEATURE:
            return {
                ...state,
                plans: state.plans.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_PLANFEATURE:
            return {
                ...state,
                plans: state.plans.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case GETPLAN_DURATION:
            return {
                ...state,
                days: payload
            };
        case PLAN_FEATURE:
            return {
                ...state,
                features: payload
            };

        default:
            return state;
    }
};
export default planReducer;
