import {
   GET_PERMISSION
} from './actiontype';

const INITIAL_STATE = {
  permissions: [],
};
const permissionReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
    case GET_PERMISSION:
      return {
        ...state,
        permissions: payload,
      };
    default:
      return state;
  }
};
export default permissionReducer;