import { GET_CLASS_FEE_DETAIL, ADD_CLASS_FEE_DETAIL } from './actiontype';

const INITIAL_STATE = {
    classFeeList: [],
    singleClassesData: [],
    classDetail: []
};
const schoolClassFeeReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_CLASS_FEE_DETAIL:
            return {
                ...state,
                classFeeList: [...state.classFeeList, payload]
            };
        case GET_CLASS_FEE_DETAIL:
            return {
                ...state,
                classFeeList: payload.fee_structure,
                classDetail: payload.class_detail
            };
        // case CLASS_DETAIL:
        //     return {
        //         ...state,
        //         classDetail: payload
        //     };
        // case SINGLE_SCHOOL_CLASS:
        //     return {
        //         ...state,
        //         singleClassesData: payload
        //     };
        // case UPDATE_SCHOOL_CLASS:
        //     return {
        //         ...state,
        //         classFeeList: state.classFeeList.map((row) => (row.id === payload.id ? { ...payload } : row))
        //     };
        // case DELETE_SCHOOL_CLASS:
        //     return {
        //         ...state,
        //         classFeeList: state.classFeeList.filter((item) => parseInt(item.id) !== parseInt(payload))
        //     };
        default:
            return state;
    }
};
export default schoolClassFeeReducer;
