import { GET_CLIENT, SINGLE_CLIENT, ADD_CLIENT, UPDATE_CLIENT, DELETE_CLIENT, UPDATE_STATUS_CLIENT } from './actiontype';

const INITIAL_STATE = {
    clients: [],
    singleClient: []
};
const clientReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_CLIENT:
            return {
                ...state,
                clients: [...state.clients, payload]
            };
        case GET_CLIENT:
            return {
                ...state,
                clients: payload
            };
        case SINGLE_CLIENT:
            return {
                ...state,
                singleClient: payload
            };
        case UPDATE_CLIENT:
            return {
                ...state,
                clients: state.clients.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case UPDATE_STATUS_CLIENT:
            return {
                ...state,
                clients: state.clients.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_CLIENT:
            return {
                ...state,
                clients: state.clients.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default clientReducer;
