import { ADD_SCHOOL_CLASS, SINGLE_SCHOOL_CLASS, UPDATE_SCHOOL_CLASS, DELETE_SCHOOL_CLASS, GET_SCHOOL_CLASSES } from './actiontype';

const INITIAL_STATE = {
    classesList: [],
    singleClassesData: []
};
const schoolClassesReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SCHOOL_CLASS:
            return {
                ...state,
                classesList: [...state.classesList, payload]
            };
        case GET_SCHOOL_CLASSES:
            return {
                ...state,
                classesList: payload
            };
        case SINGLE_SCHOOL_CLASS:
            return {
                ...state,
                singleClassesData: payload
            };
        case UPDATE_SCHOOL_CLASS:
            return {
                ...state,
                classesList: state.classesList.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_SCHOOL_CLASS:
            return {
                ...state,
                classesList: state.classesList.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default schoolClassesReducer;
