import { ADD_ROLE_PERMISSION, DELETE_ROLE_PERMISSION } from './actiontype';

const INITIAL_STATE = {
    rolepermission: [],
    isAdded: false
};
const rolePermissionReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_ROLE_PERMISSION:
            return {
                ...state,
                isAdded: true
            };
        default:
            return state;
    }
};
export default rolePermissionReducer;
