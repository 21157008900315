import { GET_EVENT, ADD_EVENT, GET_SINGLE_EVENT, DELETE_EVENT, UPDATE_EVENT, DELETE_CLASS_EVENT } from './actiontype';

const INITIAL_STATE = {
    events: []
};
const eventReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_EVENT:
            return {
                ...state,
                events: payload
            };
        case ADD_EVENT:
            return {
                ...state,
                events: [...state.events, payload]
            };
        case DELETE_EVENT:
            return {
                ...state,
                events: state.events.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        case UPDATE_EVENT:
            return {
                ...state,
                events: state.events.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_CLASS_EVENT:
            return {
                ...state,
                events: state.events.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        default:
            return state;
    }
};
export default eventReducer;
