import { ADD_CLASS, GET_CLASSES, SINGLE_CLASSES, UPDATE_CLASS, DELETE_CLASS, UPDATE_STATUS_CLASS } from './actiontype';

const INITIAL_STATE = {
    currentIntegration: [],
    singleClasses: []
};
const classesReducer = (state1 = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_CLASS:
            return {
                ...state1,
                currentIntegration: [...state1.currentIntegration, payload]
            };
        case GET_CLASSES:
            return {
                ...state1,
                currentIntegration: payload
            };
        case SINGLE_CLASSES:
            return {
                ...state1,
                singleClasses: payload
            };
        case UPDATE_CLASS:
            return {
                ...state1,
                currentIntegration: state1.currentIntegration.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case UPDATE_STATUS_CLASS:
            return {
                ...state1,
                currentIntegration: state1.currentIntegration.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_CLASS:
            return {
                ...state1,
                currentIntegration: state1.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state1;
    }
};
export default classesReducer;
