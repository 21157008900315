import { GET_ATTENDANCE, SINGLE_ATTENDANCE, ADD_ATTENDANCE, GET_STUDENT_LIST, SET_ATTENDANCE } from './actiontype';

const INITIAL_STATE = {
    studentLists: [],
    isAdded: false,
    attendanceSheet: [],
    singleList: []
};
const attendanceReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_STUDENT_LIST:
            return {
                ...state,
                studentLists: payload
            };
        case ADD_ATTENDANCE:
            return {
                ...state,
                isAdded: true
            };
        case SET_ATTENDANCE:
            return {
                ...state,
                isAdded: false
            };
        case GET_ATTENDANCE:
            return {
                ...state,
                attendanceSheet: payload
            };
        case SINGLE_ATTENDANCE:
            return {
                ...state,
                singleList: payload
            };
        default:
            return state;
    }
};
export default attendanceReducer;
