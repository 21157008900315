import { GET_SCHOOL_FEESTRUCTRES } from './actiontype';

const INITIAL_STATE = {
    feestructure: []
};

const schoolFeestructureReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_SCHOOL_FEESTRUCTRES:
            return {
                ...state,
                feestructure: payload
            };
        default:
            return state;
    }
};
export default schoolFeestructureReducer;
