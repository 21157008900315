import { combineReducers } from 'redux';
import { createSelectorHook } from 'react-redux';
import ableReducer from './ableReducer';
import demoReducer from './demoReducer';
import countryReducer from '../redux/Admin/Default/Country/reducer';
import levelReducer from '../redux/Admin/Default/Level/reducer';
import currencyReducer from '../redux/Admin/Default/Currency/reducer';
import featureReducer from '../redux/Admin/Default/Feature/reducer';
import permissionReducer from '../redux/Admin/Default/Permission/reducer';
import classesReducer from '../redux/Admin/Master/Classes/reducer';
import franchiserReducer from '../redux/Admin/Master/Franchiser/reducer';
import planReducer from '../redux/Admin/Master/Plan/reducer';
import designationReducer from '../redux/Admin/User/Designation/reducer';
import userReducer from '../redux/Admin/User/User/reducer';
import plantypeReducer from '../redux/Admin/Setting/Plantype/reducer';
import usertypeReducer from '../redux/Admin/Setting/usertype/reducer';
import schoolReducer from '../redux/Admin/School/School/reducer';
import authenticationReducer from '../redux/authentication/reducer';
import commonReducer from '../redux/Common/reducer';
import rolePermissionReducer from '../redux/Admin/User/RolePermission/reducer';
import clientReducer from '../redux/Admin/Client/Client/reducer';
import seasonReducer from '../redux/Admin/Setting/Season/reducer';
import timeperiodReducer from '../redux/Admin/Setting/Timeperiod/reducer';
//school

import schoolClassesReducer from '../redux/School/Master/Classes/reducer';
import subjectReducer from '../redux/School/Master/Subject/reducer';
import schoolUsertypeReducer from '../redux/School/User/Usertype/reducer';
import schoolDesignationReducer from '../redux/School/User/Designation/reducer';
import schoolUserReducer from '../redux/School/User/User/reducer';
import studentReducer from '../redux/School/Student/reducer';
import schoolSeasonReducer from '../redux/School/Setting/Season/reducer';
import schoolShiftReducer from '../redux/School/Setting/Shift/reducer';
import schoolTimeperiodReducer from '../redux/School/Setting/Timeperiod/reducer';
import schoolTimetableReducer from '../redux/School/Timetable/reducer';
import studentPromoteReducer from '../redux/School/Student/Promote/reducer';
import schoolTeacherTimetableReducer from '../redux/School/Timetable/TeacherTimetable/reducer';
import commonStudentReducer from '../redux/Common/School/Student/reducer';
import eventReducer from '../redux/School/Event/Event/reducer';
import commonClassReducer from '../redux/Common/School/Class/reducer';
import commonUserReducer from '../redux/Common/School/User/reducer';
import schoolSectionReducer from '../redux/School/School/School/reducer';
import feeStructureReducer from '../redux/Admin/Setting/Feestructure/reducer';
import attendanceReducer from '../redux/School/Student/Attendance/reducer';
import schoolFeestructureReducer from '../redux/School/Setting/FeeStructure/reducer';
import assignmentReducer from '../redux/School/Student/Assignment/reducer';
import schoolClassFeeReducer from '../redux/School/Master/ClassFee/reducer';
import schoolSeasonPaymentReducer from '../redux/School/Setting/SessionPaymentType/reducer';
import FeesReducer from '../redux/School/Student/Fee/reducer';

const reducer = combineReducers({
    able: ableReducer,
    demo: demoReducer,

    authentication: authenticationReducer,
    country: countryReducer,
    level: levelReducer,
    feature: featureReducer,
    permission: permissionReducer,
    manageclasses: classesReducer,
    franchiser: franchiserReducer,
    plan: planReducer,
    plantype: plantypeReducer,
    currency: currencyReducer,
    client: clientReducer,
    school: schoolReducer,
    designation: designationReducer,
    user: userReducer,
    usertype: usertypeReducer,
    rolepermission: rolePermissionReducer,
    common: commonReducer,
    season: seasonReducer,
    timeperiod: timeperiodReducer,
    feeStructure: feeStructureReducer,

    // School
    schoolClasses: schoolClassesReducer,
    subject: subjectReducer,
    schoolUsertype: schoolUsertypeReducer,
    schoolDesignation: schoolDesignationReducer,
    schoolUser: schoolUserReducer,
    student: studentReducer,
    schoolSeason: schoolSeasonReducer,
    schoolShift: schoolShiftReducer,
    schoolTimeperiod: schoolTimeperiodReducer,
    schoolTimetable: schoolTimetableReducer,
    studentPromote: studentPromoteReducer,
    schoolTeacherTimetable: schoolTeacherTimetableReducer,
    event: eventReducer,
    schoolSection: schoolSectionReducer,
    commonClass: commonClassReducer,
    commonStudent: commonStudentReducer,
    commonUser: commonUserReducer,
    attendance: attendanceReducer,
    schoolFeestructure: schoolFeestructureReducer,
    assignment: assignmentReducer,
    schoolClassFee: schoolClassFeeReducer,
    schoolSeasonPayment: schoolSeasonPaymentReducer,
    fees: FeesReducer
});
export const useSelector = createSelectorHook();
export default reducer;
