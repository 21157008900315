import {
    GET_SCHOOL_USER,
    SINGLE_SCHOOL_USER,
    ADD_SCHOOL_USER,
    UPDATE_SCHOOL_USER,
    DELETE_SCHOOL_USER,
    SINGLE_SCHOOL_USER_DETAIL
} from './actiontype';

const INITIAL_STATE = {
    users: [],
    singleUser: [],
    singleUserDetail: []
};
const schoolUserReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SCHOOL_USER:
            return {
                ...state,
                users: [...state.users, payload]
            };
        case GET_SCHOOL_USER:
            return {
                ...state,
                users: payload
            };
        case SINGLE_SCHOOL_USER:
            return {
                ...state,
                singleUser: payload
            };
        case SINGLE_SCHOOL_USER_DETAIL:
            return {
                ...state,
                singleUserDetail: payload
            };
        case UPDATE_SCHOOL_USER:
            return {
                ...state,
                users: state.users.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_SCHOOL_USER:
            return {
                ...state,
                users: state.users.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default schoolUserReducer;
