import {
    ADD_FRANCHISER,
    GET_FRANCHISERS,
    SINGLE_FRANCHISER,
    UPDATE_FRANCHISER,
    DELETE_FRANCHISER,
    UPDATE_STATUS_FRANCHISER
} from './actiontype';

const INITIAL_STATE = {
    currentIntegration: [],
    singleFranchiser: [],
    isAdded: false,
    isUpdated: false
};
const franchiserReducer = (state1 = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_FRANCHISER:
            return {
                ...state1,
                isAdded: true
            };
        case GET_FRANCHISERS:
            return {
                ...state1,
                currentIntegration: payload,
                isAdded: false,
                isUpdated: false
            };
        case SINGLE_FRANCHISER:
            return {
                ...state1,
                singleFranchiser: payload
            };
        case UPDATE_FRANCHISER:
            return {
                ...state1,
                isUpdated: true
            };
        case UPDATE_STATUS_FRANCHISER:
            return {
                ...state1,
                currentIntegration: state1.currentIntegration.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_FRANCHISER:
            return {
                ...state1,
                currentIntegration: state1.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state1;
    }
};
export default franchiserReducer;
