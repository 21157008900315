export const ADD_PLAN = 'ADD_PLAN';
export const GET_PLANS = 'GET_PLANS';
export const GETSINGLE_PLAN = 'GETSINGLE_PLAN';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const GETPLAN_DURATION = 'GETPLAN_DURATION';
export const PLAN_FEATURE = 'PLAN_FEATURE';
export const UPDATE_STATUS_PLAN = 'UPDATE_STATUS_PLAN';
export const ADD_PLANFEATURE = 'ADD_PLANFEATURE';
export const DELETE_PLANFEATURE = 'DELETE_PLANFEATURE';
