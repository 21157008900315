import {
    GET_PLANTYPE,
    ADD_PLANTYPE,
    GET_PLANTYPES,
    SINGLE_PLANTYPE,
    UPDATE_PLANTYPE,
    DELETE_PLANTYPE,
    UPDATE_STATUS_PLANTYPE
} from './actiontype';

const INITIAL_STATE = {
    plantypes: [],
    plantypeList: [],
    singleList: []
};
const plantypeReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PLANTYPE:
            return {
                ...state,
                plantypes: payload
            };
        case ADD_PLANTYPE:
            return {
                ...state,
                plantypeList: [...state.plantypeList, payload]
            };
        case GET_PLANTYPES:
            return {
                ...state,
                plantypeList: payload
            };
        case SINGLE_PLANTYPE:
            return {
                ...state,
                singleList: payload
            };
        case UPDATE_PLANTYPE:
            return {
                ...state,
                plantypeList: state.plantypeList.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case UPDATE_STATUS_PLANTYPE:
            return {
                ...state,
                plantypeList: state.plantypeList.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_PLANTYPE:
            return {
                ...state,
                plantypeList: state.plantypeList.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default plantypeReducer;
