import { ADD_SUBJECT, SINGLE_SUBJECT, UPDATE_SUBJECT, DELETE_SUBJECT, GET_SUBJECT } from './actiontype';

const INITIAL_STATE = {
    subjectList: [],
    singleSubjectData: []
};
const subjectReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SUBJECT:
            return {
                ...state,
                subjectList: [...state.subjectList, payload]
            };
        case GET_SUBJECT:
            return {
                ...state,
                subjectList: payload
            };
        case SINGLE_SUBJECT:
            return {
                ...state,
                singleSubjectData: payload
            };
        case UPDATE_SUBJECT:
            return {
                ...state,
                subjectList: state.subjectList.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_SUBJECT:
            return {
                ...state,
                subjectList: state.subjectList.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default subjectReducer;
