import { ADD_SCHOOL, UPDATE_SCHOOL, DELETE_SCHOOL, GET_SCHOOL, SINGLE_SCHOOL, SCHOOL_PLAN, COUNT_ALLOW_SCHOOL } from './actiontype';

const INITIAL_STATE = {
    currentIntegration: [],
    singleSchool: [],
    isAdded: false,
    isUpdated: false,
    school_plan: [],
    total_school: ''
};
const schoolSectionReducer = (state1 = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SCHOOL:
            return {
                ...state1,
                isAdded: true
            };
        case GET_SCHOOL:
            return {
                ...state1,
                currentIntegration: payload,
                isAdded: false
            };
        case SINGLE_SCHOOL:
            return {
                ...state1,
                singleSchool: payload
            };
        case UPDATE_SCHOOL:
            return {
                ...state1,
                isUpdated: true
            };
        case DELETE_SCHOOL:
            return {
                ...state1,
                currentIntegration: state1.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        case SCHOOL_PLAN:
            return {
                ...state1,
                school_plan: payload
            };
        case COUNT_ALLOW_SCHOOL:
            return {
                ...state1,
                total_school: payload
            };
        default:
            return state1;
    }
};
export default schoolSectionReducer;
