const chartData = {
    items: [
        {
            id: 'school',
            title: 'School',
            type: 'group',
            icon: 'icon-support',
            children: [
                {
                    id: 'school-dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/school/dashboard',
                    classes: 'nav-item',
                    icon: 'feather icon-home'
                },
                {
                    id: 'master',
                    title: 'Master',
                    type: 'collapse',
                    icon: 'feather icon-box',
                    children: [
                        {
                            id: 'manage-classes',
                            title: 'Manage Classes',
                            type: 'item',
                            url: '/school/masters/manage-classes'
                        },
                        {
                            id: 'manage-subjects',
                            title: 'Manage Subject',
                            type: 'item',
                            url: '/school/masters/manage-subject'
                        }
                    ]
                },
                {
                    id: 'student',
                    title: 'Student',
                    type: 'collapse',
                    icon: 'feather icon-user',
                    children: [
                        {
                            id: 'student',
                            title: 'Manage Student',
                            type: 'item',
                            url: '/school/manage-student'
                        },
                        {
                            id: 'promote',
                            title: 'Promote',
                            type: 'item',
                            url: '/school/promote'
                        },
                        {
                            id: 'attendance',
                            title: 'Attendance',
                            type: 'item',
                            url: '/school/student/attendance'
                        },
                        {
                            id: 'assignment',
                            title: 'Assignment',
                            type: 'item',
                            url: '/school/student/assignment'
                        },
                        {
                            id: 'fee-detail',
                            title: 'Fee Detail',
                            type: 'item',
                            url: '/school/student/fee-detail'
                        },
                        // {
                        //     id: 'fee-report',
                        //     title: 'Fee Report',
                        //     type: 'item',
                        //     url: '/school/student/fee-report'
                        // },
                        {
                            id: 'add-attendance',
                            title: 'Add Attendance',
                            type: 'item',
                            url: '/school/student/add-attendance',
                            classes: 'nav-item d-none',
                            icon: 'feather icon-user'
                        },
                        {
                            id: 'edit-attendance',
                            title: 'Edit Attendance',
                            type: 'item',
                            url: '/school/student/edit-attendance',
                            classes: 'nav-item d-none',
                            icon: 'feather icon-user'
                        },
                        {
                            id: 'add-fee',
                            title: 'Add Fee',
                            type: 'item',
                            url: '/school/student/add-fee',
                            classes: 'nav-item d-none',
                            icon: 'feather icon-user'
                        },
                        {
                            id: 'edit-fee',
                            title: 'Edit Fee',
                            type: 'item',
                            url: '/school/student/edit-fee',
                            classes: 'nav-item d-none',
                            icon: 'feather icon-user'
                        }
                    ]
                },

                {
                    id: 'add-student',
                    title: 'Add Student',
                    type: 'item',
                    url: '/school/add-student',
                    classes: 'nav-item d-none',
                    icon: 'feather icon-user'
                },
                {
                    id: 'edit-student',
                    title: 'Edit Student',
                    type: 'item',
                    url: '/school/edit-student',
                    classes: 'nav-item d-none',
                    icon: 'feather icon-user'
                },
                {
                    id: 'users1',
                    title: 'Users',
                    type: 'collapse',
                    icon: 'feather icon-users',
                    children: [
                        {
                            id: 'all-users1',
                            title: 'All Users',
                            type: 'item',
                            url: '/school/users'
                        },
                        {
                            id: 'roles-permission1',
                            title: 'Roles & Permissions',
                            type: 'item',
                            url: '/school/roles-and-permission'
                        },
                        {
                            id: 'designation1',
                            title: 'Designation',
                            type: 'item',
                            url: '/school/designation'
                        }
                    ]
                },
                {
                    id: 'event',
                    title: 'Event',
                    type: 'item',
                    url: '/school/event',
                    icon: 'feather icon-film'
                },
                {
                    id: 'time-table',
                    title: 'Time Table',
                    type: 'item',
                    url: '/school/timetable',
                    icon: 'feather icon-calendar'
                },
                {
                    id: 'teacher-time-table',
                    title: 'Teacher Time Table',
                    type: 'item',
                    url: '/school/teacher-time-table',
                    classes: 'nav-item d-none',
                    icon: 'feather icon-calendar'
                },
                {
                    id: 'Setting',
                    title: 'Setting',
                    type: 'collapse',
                    icon: 'feather icon-settings',
                    children: [
                        {
                            id: 'profile',
                            title: 'Profile',
                            type: 'item',
                            url: '/school/profile'
                        },
                        {
                            id: 'school',
                            title: 'School',
                            type: 'item',
                            url: '/school/manage-school'
                        },
                        {
                            id: 'time-period',
                            title: 'Time Period',
                            type: 'item',
                            url: '/school/time-period'
                        },
                        {
                            id: 'manage-shift',
                            title: 'Manage Shift',
                            type: 'item',
                            url: '/school/manage-shift'
                        },
                        {
                            id: 'manage-season',
                            title: 'Manage Season',
                            type: 'item',
                            url: '/school/manage-season'
                        },
                        {
                            id: 'school-fee-structure',
                            title: 'Fee Structure',
                            type: 'item',
                            url: '/school/setting/school-fee-structure'
                        },
                        {
                            id: 'payment-type',
                            title: 'Manage payment type',
                            type: 'item',
                            url: '/school/manage-payment-type'
                        }
                    ]
                }
            ]
        }
    ]
};
export default chartData;
