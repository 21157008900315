import { LOGIN, LOGOUT, SETDATA, FORGET_PASSWORD } from './actiontype';

import api from '../../api';
import { toast } from 'react-toastify';

export const login = (registerData) => async (dispatch) => {
    const body = JSON.stringify(registerData);
    try {
        const res = await api.post('/login', body);
        if (res.data.success === true) {
            localStorage.setItem('userid', res.data.data.user[0].id);
            const info = {
                name: res.data.data.user[0].name,
                phone: res.data.data.user[0].phone,
                designation: res.data.data.user[0].designation_id,
                email: res.data.data.user[0].email,
                profile: res.data.data.user[0].profile,
                no_of_school: res.data.data.user[0].no_of_school
            };
            localStorage.setItem('user_info', JSON.stringify(info));
            localStorage.setItem('login_type', res.data.data.type);

            if (res.data.data.type === 'client') {
                localStorage.setItem('school_id', res.data.data.school_id.id);
            }
            dispatch({
                type: LOGIN,
                payload: res.data
            });
        } else {
            toast.error(res.data.data.error);
        }
    } catch (err) {
        toast.error('Something Went Wrong');
        dispatch({
            type: LOGIN,
            payload: []
        });
    }
};

export const logout = (registerData) => async (dispatch) => {
    localStorage.removeItem('user_info');
    localStorage.removeItem('userid');
    dispatch({
        type: LOGOUT,
        payload: []
    });
};

export const changePassword = (data) => async (dispatch) => {
    if (data.newPassword != data.confirmPassword) {
        toast.error('Confirm Password and New Password must be same');
    } else {
        var detail = data;
        detail['id'] = localStorage.getItem('userid');
        const body = JSON.stringify(detail);

        try {
            const res = await api.post('/changePassword', body);
            if (res.data.success === true) {
                toast.success(res.data.message.success);
                dispatch({
                    type: LOGIN,
                    payload: res.data
                });
            } else {
                toast.error(res.data.data);
            }
        } catch (err) {
            toast.error('Something Went Wrong');
            dispatch({
                type: LOGIN,
                payload: []
            });
        }
    }
};

export const setData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SETDATA,
            payload: 'test'
        });
    } catch (err) {
        dispatch({
            type: SETDATA,
            payload: []
        });
    }
};

export const continueLogin = (data) => async (dispatch) => {
    const items = JSON.parse(localStorage.getItem('user_info'));
    var detail = data;
    detail['email'] = items.email;
    const body = JSON.stringify(detail);

    try {
        const res = await api.post('/lockscreen', body);
        if (res.data.success === true) {
            dispatch({
                type: LOGIN,
                payload: res.data
            });
        } else {
            toast.error(res.data.data.error);
        }
    } catch (err) {
        toast.error('Something Went Wrong');
        dispatch({
            type: LOGIN,
            payload: []
        });
    }
};

export const resetPassword = (data) => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
        const res = await api.post('/resetPassword', body);
        if (res.data.success === true) {
            toast.error(res.data.message);
            dispatch({
                type: FORGET_PASSWORD,
                payload: res.data
            });
        } else {
            toast.error(res.data.data.error);
        }
    } catch (err) {
        toast.error('Something Went Wrong');
        dispatch({
            type: FORGET_PASSWORD,
            payload: []
        });
    }
};
