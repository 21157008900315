import { USERTYPE_LIST, STATE_LIST, USERTYPE_DESIGNATION, SCHOOL_USERTYPE_LIST } from './actiontype';

const INITIAL_STATE = {
    usertypes: [],
    schoolUsertypes: [],
    states: [],
    usertypeDesignation: []
};
const commonReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case USERTYPE_LIST:
            return {
                ...state,
                usertypes: payload
            };
        case SCHOOL_USERTYPE_LIST:
            return {
                ...state,
                schoolUsertypes: payload
            };
        case STATE_LIST:
            return {
                ...state,
                states: payload
            };
        case USERTYPE_DESIGNATION:
            return {
                ...state,
                usertypeDesignation: payload
            };
        default:
            return state;
    }
};
export default commonReducer;
