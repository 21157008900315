import {
    GET_STUDENT,
    SINGLE_STUDENT,
    ADD_STUDENT,
    UPDATE_STUDENT,
    DELETE_STUDENT,
    STATUS_STUDENT,
    CLASS_DETAIL,
    FEE_DETAIL
} from './actiontype';

const INITIAL_STATE = {
    students: [],
    singleStudent: [],
    isAdded: false,
    isUpdated: false,
    class_detail: [],
    fee_structure: []
};
const studentReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_STUDENT:
            return {
                ...state,
                isAdded: true
            };
        case GET_STUDENT:
            return {
                ...state,
                students: payload,
                isAdded: false,
                isUpdated: false
            };
        case CLASS_DETAIL:
            return {
                ...state,
                class_detail: payload
            };
        case FEE_DETAIL:
            return {
                ...state,
                fee_structure: payload
            };
        case SINGLE_STUDENT:
            return {
                ...state,
                singleStudent: payload
            };
        case UPDATE_STUDENT:
            return {
                ...state,
                isUpdated: true
            };
        case STATUS_STUDENT:
            return {
                ...state,
                students: state.students.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_STUDENT:
            return {
                ...state,
                students: state.students.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default studentReducer;
