import { USER_LIST } from './actiontype';

const INITIAL_STATE = {
    user_lists: []
};
const commonUserReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LIST:
            return {
                ...state,
                user_lists: payload
            };
        default:
            return state;
    }
};
export default commonUserReducer;
