import { ADD_SCHOOL_SHIFT, GET_SCHOOL_SHIFTS, GETSINGLE_SCHOOL_SHIFT, UPDATE_SCHOOL_SHIFT, DELETE_SCHOOL_SHIFT } from './actiontype';

const INITIAL_STATE = {
    shiftList: [],
    singleShift: []
};

const schoolShiftReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SCHOOL_SHIFT:
            return {
                ...state,
                shiftList: [...state.shiftList, payload]
            };
        case GET_SCHOOL_SHIFTS:
            return {
                ...state,
                shiftList: payload
            };
        case GETSINGLE_SCHOOL_SHIFT:
            return {
                ...state,
                singleShift: payload
            };
        case UPDATE_SCHOOL_SHIFT:
            return {
                ...state,
                shiftList: payload
            };
        case DELETE_SCHOOL_SHIFT:
            return {
                ...state,
                shiftList: state.shiftList.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default schoolShiftReducer;
