import { GET_TEACHER_TIMETABLES } from './actiontype';

const INITIAL_STATE = {
    teacherTimetableList: []
};
const schoolTeacherTimetableReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TEACHER_TIMETABLES:
            return {
                ...state,
                teacherTimetableList: payload
            };
        default:
            return state;
    }
};
export default schoolTeacherTimetableReducer;
