import {
    GET_FEATURE,
    ADD_FEATURE,
    GET_FEATURES,
    SINGLE_FEATURE,
    UPDATE_FEATURE,
    DELETE_FEATURE,
    UPDATE_STATUS_FEATURE
} from './actiontype';

const INITIAL_STATE = {
    features: [],
    featureList: [],
    singleList: []
};
const featureReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_FEATURE:
            return {
                ...state,
                features: payload
            };
        case ADD_FEATURE:
            return {
                ...state,
                featureList: [...state.featureList, payload]
            };
        case GET_FEATURES:
            return {
                ...state,
                featureList: payload
            };
        case SINGLE_FEATURE:
            return {
                ...state,
                singleList: payload
            };
        case UPDATE_FEATURE:
            return {
                ...state,
                featureList: payload
            };
        case UPDATE_STATUS_FEATURE:
            return {
                ...state,
                featureList: state.featureList.map((row) =>
                    row.id === payload.id
                        ? {
                              ...payload
                          }
                        : row
                )
            };
        case DELETE_FEATURE:
            return {
                ...state,
                featureList: state.featureList.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default featureReducer;
