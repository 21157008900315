import { GET_USER, SINGLE_USER, ADD_USER, UPDATE_USER, DELETE_USER, SINGLE_USER_DETAIL, UPDATE_STATUS_USER } from './actiontype';

const INITIAL_STATE = {
    users: [],
    singleUser: [],
    singleUserDetail: []
};
const userReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_USER:
            return {
                ...state,
                users: [...state.users, payload]
            };
        case GET_USER:
            return {
                ...state,
                users: payload
            };
        case SINGLE_USER:
            return {
                ...state,
                singleUser: payload
            };
        case SINGLE_USER_DETAIL:
            return {
                ...state,
                singleUserDetail: payload
            };
        case UPDATE_USER:
            return {
                ...state,
                users: state.users.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case UPDATE_STATUS_USER:
            return {
                ...state,
                users: state.users.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default userReducer;
