import {
    ADD_USERTYPE,
    GET_USERTYPES,
    GETSINGLE_USERTYPE,
    UPDATE_USERTYPE,
    DELETE_USERTYPE,
    PERMISSION_DATA,
    UPDATE_STATUS_USERTYPE,
    ADD_USER_PERMISSION,
    DELETE_USER_PERMISSION
} from './actiontype';

const INITIAL_STATE = {
    usertypes: [],
    singleUsertype: [],
    permission_list: ''
};

const usertypeReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_USERTYPE:
            return {
                ...state,
                usertypes: [...state.usertypes, payload]
            };
        case GET_USERTYPES:
            return {
                ...state,
                usertypes: payload
            };
        case GETSINGLE_USERTYPE:
            return {
                ...state,
                singleUsertype: payload
            };
        case UPDATE_USERTYPE:
            return {
                ...state,
                usertypes: payload
            };
        case UPDATE_STATUS_USERTYPE:
            return {
                ...state,
                usertypes: state.usertypes.map((row) => (row.id === payload.id ? { ...payload } : row))
            };
        case PERMISSION_DATA:
            return {
                ...state,
                permission_list: payload
            };
        case DELETE_USERTYPE:
            return {
                ...state,
                usertypes: state.usertypes.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        default:
            return state;
    }
};
export default usertypeReducer;
