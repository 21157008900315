import { CLASS_LIST } from './actiontype';

const INITIAL_STATE = {
    class_lists: []
};
const commonClassReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case CLASS_LIST:
            return {
                ...state,
                class_lists: payload
            };
        default:
            return state;
    }
};
export default commonClassReducer;
