import { ADD_SCHOOL, UPDATE_SCHOOL, DELETE_SCHOOL, GET_SCHOOL, SINGLE_SCHOOL, SCHOOL_PLAN, UPDATE_STATUS_SCHOOL } from './actiontype';

const INITIAL_STATE = {
    currentIntegration: [],
    singleSchool: [],
    isAdded: false,
    isUpdated: false,
    school_plan: []
};
const schoolReducer = (state1 = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SCHOOL:
            return {
                ...state1,
                isAdded: true
            };
        case GET_SCHOOL:
            return {
                ...state1,
                currentIntegration: payload,
                isAdded: false,
                isUpdated: false
            };
        case SINGLE_SCHOOL:
            return {
                ...state1,
                singleSchool: payload
            };
        case UPDATE_SCHOOL:
            return {
                ...state1,
                isUpdated: true
            };
        case UPDATE_STATUS_SCHOOL:
            return {
                ...state1,
                currentIntegration: state1.currentIntegration.map((row) =>
                    row.id === payload.id
                        ? {
                              ...payload
                          }
                        : row
                )
            };
        case DELETE_SCHOOL:
            return {
                ...state1,
                currentIntegration: state1.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload))
            };
        case SCHOOL_PLAN:
            return {
                ...state1,
                school_plan: payload
            };
        default:
            return state1;
    }
};
export default schoolReducer;
